import React, { useEffect, useRef, useState } from "react";

const LazyImage = (props) => {
  const [inView, setInView] = useState(false);
  const ref = useRef();
  let callback = (entrise, observer) => {
    entrise.forEach((entry) => {
      if (entry.isIntersecting) {
        setInView(true);
      }
    });
  };

  useEffect(() => {
    let observer = new IntersectionObserver(callback);
    if (ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return inView ? (
    <img {...props} />
  ) : (
    <div
      ref={ref}
      style={{
        width: props.width || "100%",
        height: props.height || "auto",
        backgroundColor: "#9a9c9f",
      }}
    >
      Loading...
    </div>
  );
};

export default LazyImage;
