import React from "react";
import "./mapNumber.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { FormattedMessage } from "react-intl";

const data = [
  {
    value: "98",
    title: (
      <FormattedMessage
        id="home-map-number-satisfaction"
        defaultMessage="客戶滿意度高達"
      />
    ),
    suffix: "%",
  },
  {
    value: "3500",
    title: (
      <FormattedMessage
        id="home-map-number-customer"
        defaultMessage="全球擁有"
      />
    ),
    suffix: "+",
  },
  {
    value: "2.5",
    title: (
      <FormattedMessage
        id="home-map-number-report"
        defaultMessage="分析報告推送到顧客手機上"
      />
    ),
    suffix: "M",
  },
];

const MapNumber = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
    rootMargin: "-80px",
  });

  return (
    <div className="mapNumber" ref={ref}>
      <Row>
        {data.map((item, index) => (
          <Col key={index} lg={4} md={4}>
            <div className="itemContainer">
              <h2>
                {inView ? (
                  <CountUp end={item.value} suffix={item.suffix} duration={1} />
                ) : (
                  "0" + item.suffix
                )}
              </h2>
              <h3>{item.title}</h3>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MapNumber;
