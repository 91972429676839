import React from "react";
import "./map.scss";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import MapPin from "../../../assets/icon/map-pin-logo.svg";

const customIcon = new L.Icon({
  iconUrl: MapPin,
  iconSize: [50, 50],
  iconAnchor: [17, 35],
  popupAnchor: [0, -35],
});

const Map = () => {
  const position = [25.0869, 121.52244];

  return (
    <div className="map">
      <MapContainer
        center={position}
        zoom={18}
        style={{ height: "400px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} icon={customIcon}>
          <Popup>堅兵智能科技</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
