import React, { useState } from "react";
import "./floatAd.scss";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { useIntl, FormattedMessage } from "react-intl";

const FloatAd = ({ post }) => {
  const [show, setShow] = useState(true);
  const intl = useIntl();
  const title = intl.locale.includes("zh") ? "title-zh" : "title-en";

  const handleClose = () => {
    setShow(false);
  };

  const formattedDate = moment.unix(post.post_ts).utc().format("YYYY-MM-DD");

  const handleGAClick = (platform) => {
    ReactGA.event({
      category: "postview",

      action: "Click",
      label: platform,
    });
  };

  return (
    <>
      {show && (
        <div className={"floatAd"}>
          <div className="closeBtn" onClick={handleClose}>
            <i className="icon"></i>
          </div>
          <div className="textContainer">
            <p className="date">{formattedDate}</p>
            <h3 className={title}>{post.title}</h3>
          </div>
          <Link to={`/news/${post.news_uid}`}>
            <button onClick={() => handleGAClick("Float-Ad")}>
              <FormattedMessage
                id="home-float-button"
                defaultMessage="瞭解更多"
              />
            </button>
          </Link>
        </div>
      )}
    </>
  );
};

export default FloatAd;
