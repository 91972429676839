import React, { useState } from "react";
import "./globalMap.scss";
import Map from "../../../assets/image/map.png";
import LazyImage from "../../lazyImage/LazyImage";
import { FormattedMessage } from "react-intl";

const GlobalMap = () => {
  const [hoverData, setHoverData] = useState(null);
  const handleMouseEnter = (item) => {
    setHoverData({
      name: item.name,
      top: item.top,
      left: item.left,
    });
  };

  const handleMouseLeave = () => {
    setHoverData(null);
  };

  console.log(hoverData);

  return (
    <div className="globalMap">
      <div className="mapContainer">
        <LazyImage src={Map} className="map" />
        {/* <img className="map" src={Map} alt="Global-Map"  /> */}
        {hoverData && (
          <p
            className="hoverText show"
            style={{
              top: `${hoverData.top}%`,
              left: `${hoverData.left}%`,
            }}
          >
            {hoverData.name}
          </p>
        )}
        {[
          // {
          //   country: "italy",
          //   name: (
          //     <FormattedMessage
          //       id="home-global-map-italy"
          //       defaultMessage="義大利"
          //     />
          //   ),
          //   gradientId: "gradient-italy",
          //   top: 37.5,
          //   left: 49.5,
          // },
          {
            country: "china",
            name: (
              <FormattedMessage
                id="home-global-map-china"
                defaultMessage="中國"
              />
            ),
            gradientId: "gradient-china",
            top: 40.5,
            left: 72.5,
          },

          {
            country: "singapore",
            name: (
              <FormattedMessage
                id="home-global-map-singapore"
                defaultMessage="新加坡"
              />
            ),
            gradientId: "gradient-singapore",
            top: 57,
            left: 75.5,
          },
          {
            country: "usa",
            name: (
              <FormattedMessage
                id="home-global-map-usa"
                defaultMessage="美國"
              />
            ),
            gradientId: "gradient-usa",
            top: 44,
            left: 20.8,
          },
          // {
          //   country: "mexico",
          //   name: (
          //     <FormattedMessage
          //       id="home-global-map-mexico"
          //       defaultMessage="墨西哥"
          //     />
          //   ),
          //   gradientId: "gradient-mexico",
          //   top: 50,
          //   left: 19.6,
          // },
          {
            country: "japan",
            name: (
              <FormattedMessage
                id="home-global-map-japan"
                defaultMessage="日本"
              />
            ),
            gradientId: "gradient-japan",
            top: 42,
            left: 82.5,
          },
          {
            country: "hongkong",
            name: (
              <FormattedMessage
                id="home-global-map-hongkong"
                defaultMessage="香港"
              />
            ),
            gradientId: "gradient-hongkong",
            top: 48,
            left: 77.5,
          },
          {
            country: "macao",
            name: (
              <FormattedMessage
                id="home-global-map-macao"
                defaultMessage="澳門"
              />
            ),
            gradientId: "gradient-macao",
            top: 48,
            left: 75.5,
          },
          {
            country: "vietnam",
            name: (
              <FormattedMessage
                id="home-global-map-vietnam"
                defaultMessage="越南"
              />
            ),
            gradientId: "gradient-vietnam",
            top: 52,
            left: 75,
          },
          {
            country: "thailand",
            name: (
              <FormattedMessage
                id="home-global-map-thailand"
                defaultMessage="泰國"
              />
            ),
            gradientId: "gradient-thailand",
            top: 52,
            left: 73,
          },
          {
            country: "malaysia ",
            name: (
              <FormattedMessage
                id="home-global-map-malaysia"
                defaultMessage="馬來西亞"
              />
            ),
            gradientId: "gradient-malaysia",
            top: 55.5,
            left: 74,
          },
          {
            country: "indonesia",
            name: (
              <FormattedMessage
                id="home-global-map-indonesia"
                defaultMessage="印尼"
              />
            ),
            gradientId: "gradient-indonesia",
            top: 59.5,
            left: 77.5,
          },
          {
            country: "france",
            name: (
              <FormattedMessage
                id="home-global-map-france"
                defaultMessage="法國"
              />
            ),
            gradientId: "gradient-france",
            top: 35,
            left: 47.5,
          },
          {
            country: "india",
            name: (
              <FormattedMessage
                id="home-global-map-india"
                defaultMessage="India"
              />
            ),
            gradientId: "gradient-india",
            top: 48.5,
            left: 67.5,
          },
          {
            country: "sriLanka",
            name: (
              <FormattedMessage
                id="home-global-map-sriLanka"
                defaultMessage="Sri Lanka"
              />
            ),
            gradientId: "gradient-sriLanka",
            top: 51.5,
            left: 68.5,
          },
        ].map((item, index) => (
          <svg
            key={index}
            className={item.country}
            viewBox="0 0 102.467 110.622"
            onMouseEnter={() => handleMouseEnter(item)}
            onMouseLeave={handleMouseLeave}
          >
            <defs>
              <linearGradient
                id={item.gradientId}
                x1="0.026"
                y1="0.061"
                x2="0.533"
                y2="0.601"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stop-color="var(--start-color, #00bfb3)" />
                <stop offset="1" stop-color="var(--end-color, #606062)" />
              </linearGradient>
              <filter
                id="Path_362"
                x="0"
                y="0"
                width="72.467"
                height="90.622"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood flood-color="#6b6c6e" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_362)">
              <path
                id="Path_362-2"
                data-name="Path 362"
                d="M4,27.233a27.233,27.233,0,1,1,54.467,0c0,18.156-27.233,45.389-27.233,45.389S4,45.389,4,27.233m15.886,0A11.347,11.347,0,1,0,31.233,15.886,11.347,11.347,0,0,0,19.886,27.233Z"
                transform="translate(5 6)"
                fill={`url(#${item.gradientId})`}
              />
            </g>
          </svg>
        ))}
      </div>
    </div>
  );
};

export default GlobalMap;
