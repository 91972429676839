import React, { useEffect, useState } from "react";
import "./header.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/image/Navbar-logo.png";
import ScrolledLogo from "../../assets/image/Navbar-scrolled-Logo.png";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useLanguage } from "../../context/LanguageContext";
import Offcanvas from "react-bootstrap/Offcanvas";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const { switchLanguage } = useLanguage();
  const intl = useIntl();
  const button = intl.locale.includes("zh") ? "button-zh" : "button-en";
  const pricingStyle = intl.locale.includes("zh-TW")
    ? "pricing-zh"
    : "pricing-en";

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="header">
      <Navbar
        fixed="top"
        expand="lg"
        className={`bg-body-tertiary navbar ${scrolled ? "scrolled" : ""}`}
      >
        <div className="navbarContainer">
          <Link to={"/"}>
            <Navbar.Brand href="/" className="logoContainer">
              <img src={scrolled ? ScrolledLogo : logo} alt="Sentra-Smart" />
            </Navbar.Brand>
          </Link>
          <div className="navLinkContainer">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className={`toggle ${scrolled ? "scrolled" : ""}`}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand`}
              aria-labelledby={`offcanvasNavbarLabel-expand`}
              placement="start"
            >
              <Offcanvas.Header closeButton className="offcanvasHeader">
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                  <Link to={"/"}>
                    <img src={ScrolledLogo} alt="" />
                  </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto nav">
                  <Link to={"/about"}>
                    <Nav.Link
                      href="/about"
                      className={`navLink ${scrolled ? "scrolled" : ""}`}
                    >
                      <FormattedMessage
                        id="header-about"
                        defaultMessage="關於我們"
                      />
                    </Nav.Link>
                  </Link>
                  <NavDropdown
                    title={
                      <FormattedMessage
                        id="header-solutions"
                        defaultMessage="產品服務"
                      />
                    }
                    id="basic-nav-dropdown"
                    className={`navdropdown  ${scrolled ? "scrolled" : ""}`}
                  >
                    <Link to={"/product/scalp"}>
                      <NavDropdown.Item href="/product/scalp">
                        <FormattedMessage
                          id="header-scalp"
                          defaultMessage="頭皮智能檢測系統"
                        />
                      </NavDropdown.Item>
                    </Link>
                    <Link to={"/product/skin"}>
                      <NavDropdown.Item href="/product/skin">
                        <FormattedMessage
                          id="header-skin"
                          defaultMessage="皮膚智能檢測系統"
                        />
                      </NavDropdown.Item>
                    </Link>
                    <Link to={"/product/wellness"}>
                      <NavDropdown.Item href="/product/wellness">
                        <FormattedMessage
                          id="header-wellness"
                          defaultMessage="大健康"
                        />
                      </NavDropdown.Item>
                    </Link>
                    <Link to={"/product/reservation"}>
                      <NavDropdown.Item href="/product/reservation">
                        <FormattedMessage
                          id="header-reservation"
                          defaultMessage="線上預約系統"
                        />
                      </NavDropdown.Item>
                    </Link>
                    <Link to={"/product/online-shop"}>
                      <NavDropdown.Item href="/product/online-shop">
                        <FormattedMessage
                          id="header-online-shop"
                          defaultMessage="線上商城系統"
                        />
                      </NavDropdown.Item>
                    </Link>
                  </NavDropdown>
                  <Link to={"/pricing"} className={pricingStyle}>
                    <Nav.Link
                      href="#link"
                      className={`navLink ${scrolled ? "scrolled" : ""}`}
                    >
                      <FormattedMessage
                        id="header-pricing"
                        defaultMessage="方案費用"
                      />
                    </Nav.Link>
                  </Link>
                  <Link to={"/news"}>
                    <Nav.Link
                      href="/news"
                      className={`navLink ${scrolled ? "scrolled" : ""}`}
                    >
                      <FormattedMessage
                        id="header-news"
                        defaultMessage="最新消息"
                      />
                    </Nav.Link>
                  </Link>
                  <Link to={"/recommend"}>
                    <Nav.Link
                      href="#link"
                      className={`navLink ${scrolled ? "scrolled" : ""}`}
                    >
                      <FormattedMessage
                        id="header-recommend"
                        defaultMessage="好評推薦"
                      />
                    </Nav.Link>
                  </Link>
                  <Link to={"/dm"}>
                    <Nav.Link
                      href="/dm"
                      className={`navLink ${scrolled ? "scrolled" : ""}`}
                    >
                      <FormattedMessage
                        id="header-dm"
                        defaultMessage="產品目錄"
                      />
                    </Nav.Link>
                  </Link>
                  <NavDropdown
                    title={
                      <FormattedMessage
                        id="header-language"
                        defaultMessage="語言"
                      />
                    }
                    id="basic-nav-dropdown"
                    className={`language ${scrolled ? "scrolled" : ""}`}
                  >
                    <Link to={""}>
                      <NavDropdown.Item onClick={() => switchLanguage("zh-TW")}>
                        繁體中文
                      </NavDropdown.Item>
                    </Link>
                    {/* <Link to={""}>
                      <NavDropdown.Item onClick={() => switchLanguage("zh-CN")}>
                        简体中文
                      </NavDropdown.Item>
                    </Link> */}
                    <Link to={""}>
                      <NavDropdown.Item onClick={() => switchLanguage("en")}>
                        English
                      </NavDropdown.Item>
                    </Link>
                    <Link to={""}>
                      <NavDropdown.Item onClick={() => switchLanguage("th")}>
                        ไทย
                      </NavDropdown.Item>
                    </Link>
                    <Link to={""}>
                      <NavDropdown.Item onClick={() => switchLanguage("id")}>
                        Bahasa Indonesia
                      </NavDropdown.Item>
                    </Link>
                    <Link to={""}>
                      <NavDropdown.Item onClick={() => switchLanguage("vi")}>
                        Tiếng Việt
                      </NavDropdown.Item>
                    </Link>
                    <Link to={""}>
                      <NavDropdown.Item onClick={() => switchLanguage("ja")}>
                        日本語
                      </NavDropdown.Item>
                    </Link>
                  </NavDropdown>
                  <Nav.Link
                    href="#link"
                    className={`navLink ${scrolled ? "scrolled" : ""}`}
                  >
                    <Link to={"/contact"}>
                      <button className={button}>
                        <FormattedMessage
                          id="button-contact"
                          defaultMessage="立即諮詢"
                        />
                      </button>
                    </Link>
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
