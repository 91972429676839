import React, { useState, lazy, Suspense } from "react";
import "./partnerBrand.scss";
import HairProductImage from "../../../assets/image/brand-hair-product.png";
import ScalpCareImage from "../../../assets/image/brand-scalp-care.png";
import MedicalImage from "../../../assets/image/brand-medical-aesthetics.png";
import HairSalon from "../../../assets/image/brand-hair-salon.png";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import LazyImage from "../../lazyImage/LazyImage";

const categories = [
  {
    name: (
      <FormattedMessage
        id="home-brand-hair-product"
        defaultMessage="專業髮品商"
      />
    ),
    imageUrl: HairProductImage,
  },
  {
    name: (
      <FormattedMessage
        id="home-brand-scalp-care"
        defaultMessage="專業頭皮養護"
      />
    ),
    imageUrl: ScalpCareImage,
  },
  {
    name: (
      <FormattedMessage id="home-brand-medical" defaultMessage="醫美診所" />
    ),
    imageUrl: MedicalImage,
  },
  {
    name: (
      <FormattedMessage id="home-brand-hair-salon" defaultMessage="知名髮廊" />
    ),
    imageUrl: HairSalon,
  },
];

const PartnerBrand = () => {
  const [selectedCategory, setSelectCategory] = useState(categories[0]);
  const intl = useIntl();
  const locale = intl.locale;

  const button = locale.includes("zh") ? "button-zh" : "button-en";

  return (
    <div className="partnerBrand">
      <div className="buttonContainer">
        {categories.map((categorie, index) => (
          <button
            key={index}
            onMouseEnter={() => setSelectCategory(categorie)}
            className={button}
          >
            {categorie.name}
          </button>
        ))}
      </div>
      <div className="imgaeContainer">
        <LazyImage
          src={selectedCategory.imageUrl}
          alt={selectedCategory.name}
        />
        {/* <Suspense fallback={<div>Loading...</div>}>
          <img src={selectedCategory.imageUrl} alt={selectedCategory.name} />
        </Suspense> */}
      </div>
    </div>
  );
};

export default PartnerBrand;
