import React, { useState, useEffect, lazy, Suspense } from "react";
import "./styles/App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage/HomePage";
import { IntlProvider } from "react-intl";
import { LanguageProvider, useLanguage } from "./context/LanguageContext";
import ScrollTop from "./compoments/scrollTop/ScrollTop";
import ReactGA from "react-ga4";
import NotFoundPage from "./pages/notFoundPage/NotFoundPage";
const ContactPage = lazy(() => import("./pages/contactPage/ContactPage"));
const AboutPage = lazy(() => import("./pages/aboutPage/AboutPage"));
const ScalpPage = lazy(() => import("./pages/scalpPage/ScalpPage"));
const SkinPage = lazy(() => import("./pages/skinPage/SkinPage"));
const ReservationPage = lazy(() =>
  import("./pages/reservationPage/ReservationPage")
);
const OnlineShopPage = lazy(() =>
  import("./pages/olineShopPage/OnlineShopPage")
);
const WellnessPage = lazy(() => import("./pages/wellnessPage/WellnessPage"));
const NewsPage = lazy(() => import("./pages/newsPage/NewsPage"));
const PostPage = lazy(() => import("./pages/postPage/PostPage"));
const RecommendPage = lazy(() => import("./pages/recommendPage/RecommendPage"));
const PricePage = lazy(() => import("./pages/pricePage/PricePage"));
const DmPage = lazy(() => import("./pages/dmPage/DmPage"));
const AlluringPage = lazy(() => import("./pages/alluringPage/AlluringPage"));

function App() {
  const { language, messages } = useLanguage();
  const [locale, setLocale] = useState(language);
  const TRACKING_ID = "G-HVCQ7CXKBT";

  useEffect(() => {
    setLocale(language);
  }, [language]);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
  }, []);

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  return (
    <div className={`App ${locale}`}>
      <IntlProvider
        locale={language}
        key={language}
        defaultLocale="zh"
        messages={messages}
      >
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route
              path="/about"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AboutPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/contact"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <ContactPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/product/scalp"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <ScalpPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/product/skin"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <SkinPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/product/reservation"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <ReservationPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/product/online-shop"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <OnlineShopPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/dm"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <DmPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/product/wellness"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <WellnessPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/news"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <NewsPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/news/:postId"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <PostPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/recommend"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <RecommendPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/pricing"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <PricePage />
                </Suspense>
              }
            ></Route>
            <Route
              path="*"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <NotFoundPage />
                </Suspense>
              }
            ></Route>
            <Route
              path="/alluring"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AlluringPage />
                </Suspense>
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </div>
  );
}

const AppWrapper = () => {
  return (
    <LanguageProvider>
      <App />
    </LanguageProvider>
  );
};

export default AppWrapper;
