import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const loadLocaleData = (locale) => {
  switch (locale) {
    case "en":
      return import("../lang/locales/en.js");
    case "zh-TW":
      return import("../lang/locales/zh.js");
    // case "zh-CN":
    //   return import("../lang/locales/zh-CN.js");
    case "th":
      return import("../lang/locales/th.js");
    case "id":
      return import("../lang/locales/id.js");
    case "vi":
      return import("../lang/locales/vi.js");
    case "ja":
      return import("../lang/locales/ja.js");
    default:
      return import("../lang/locales/en.js");
  }
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || navigator.language
  );
  const [messages, setMessages] = useState({});

  useEffect(() => {
    loadLocaleData(language).then((data) => {
      setMessages(data.default);
    });
  }, [language]);

  const switchLanguage = (lang) => {
    localStorage.setItem("language", lang);
    setLanguage(lang);
    console.log(language);
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage, messages }}>
      {children}
    </LanguageContext.Provider>
  );
};
