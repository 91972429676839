import React, { useEffect } from "react";

const useWebMetaData = (title, description) => {
  useEffect(() => {
    document.title = `${title}Sentra Smart`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);
  }, [title, description]);
};

export default useWebMetaData;
