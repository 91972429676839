import React from "react";
import "./join.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ReactGA from "react-ga4";
import LazyImage from "../lazyImage/LazyImage";

const handleGAClick = (platform) => {
  ReactGA.event({
    category: "pageview",
    action: "Click",
    label: platform,
  });
};

const Join = ({ textColor, buttonColor, background }) => {
  return (
    <div className="join">
      <LazyImage src={background} alt={"join-us"} />
      {/* <img src={background} alt="join-us" /> */}
      <div className="container">
        <div className="textContainer">
          <h1 style={{ color: textColor }}>
            <FormattedMessage
              id="join-first-title"
              defaultMessage="加入我們！"
            />
          </h1>
          <h1 style={{ color: textColor }}>
            <FormattedMessage
              id="join-second-title"
              defaultMessage="我們將幫您的業務提升到新的高度"
            />
          </h1>
        </div>
        <Link to={"/contact"} onClick={() => handleGAClick("join-us-contact")}>
          <button style={{ backgroundColor: buttonColor }}>
            <FormattedMessage
              id="join-button-text"
              defaultMessage="立即與我們聯繫"
            />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Join;
