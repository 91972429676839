import React from "react";
import "./footer.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/image/footer logo.png";
import FacebookIcon from "../../assets/icon/facebook.svg";
import MailIcon from "../../assets/icon/Icon-feather-mail.svg";
import PhoneIcon from "../../assets/icon/Icon-feather-phone.svg";
import { FormattedMessage, useIntl } from "react-intl";
import ReactGA from "react-ga4";

const Footer = () => {
  const intl = useIntl();
  const adressTitle = intl.locale.includes("en")
    ? "adressTitle-en"
    : "adressTitle-zh";
  const handleClick = (platform) => {
    ReactGA.event({
      category: "Social Links",
      action: "Click",
      label: platform,
    });
  };
  return (
    <div className="footer">
      <div className="footerContainer">
        <Row>
          <Col lg={3} md={12}>
            <div className="logoContainer">
              <img src={Logo} alt="Sentra Smart" />
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="address">
              <h5 className={adressTitle}>
                <FormattedMessage
                  id="footer-company-6F"
                  defaultMessage="堅兵智能科技"
                />
              </h5>
              <p className="descriptionTC">台北市士林區承德路四段182號6樓</p>
              <p className="descriptionEN">
                6 F., No.182, Sec. 4, Chengde Rd., Shilin Dist., Taipei City
                111052, Taiwan
              </p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="address leCoeur">
              <h5 className={adressTitle}>
                <FormattedMessage
                  id="footer-company-9F"
                  defaultMessage="心美學研習空間"
                />
              </h5>
              <p className="descriptionTC">台北市士林區承德路四段184號9樓</p>
              <p className="descriptionEN">
                9 F., No.184, Sec. 4, Chengde Rd., Shilin Dist., Taipei City
                111052, Taiwan
              </p>
            </div>
          </Col>
          <Col lg={3} md={12}>
            <div className="contact">
              <a
                href="https://www.facebook.com/alluring.sentrasmart"
                target="blank"
                onClick={() => handleClick("Facebook")}
              >
                <div className="facebook">
                  <img src={FacebookIcon} alt="facebook" />
                  <span>facebook</span>
                </div>
              </a>
              <a
                href="mailto:sales@sentrasmart.com"
                onClick={() => handleClick("Email")}
              >
                <div className="mail">
                  <img src={MailIcon} alt="mail" />
                  <span>sales@sentrasmart.com</span>
                </div>
              </a>
              <a href="tel:+886288615688" onClick={() => handleClick("Phone")}>
                <div className="phone">
                  <img src={PhoneIcon} alt="phone-number" />
                  <span>+886288615688</span>
                </div>
              </a>

              <div className="taxId">
                <p>
                  <FormattedMessage
                    id="footer-tax-id"
                    defaultMessage="統一編號 42648493"
                  />
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <p className="copyright">Copyright © 2024 Sentra Smart</p>
    </div>
  );
};

export default Footer;
