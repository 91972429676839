import React, { useEffect } from "react";
import "./notFoundPage.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import useWebMetaData from "../../hook/useWebMetaData";

const NotFoundPage = () => {
  useWebMetaData("404 - ");

  return (
    <div className="notFoundPage">
      <div className="textContainer">
        <h1>404</h1>
        <p>Oops... page not found!</p>
      </div>
      <Link to={"/"}>
        <button>
          <FormattedMessage id="notFound-button" defaultMessage="返回首頁" />
        </button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
