import React from "react";
import "./featureGallery.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image1 from "../../../assets/image/gallery-partner.png";
import Image2 from "../../../assets/image/gallery-eight-minute.png";
import Image3 from "../../../assets/image/gallery-easy-learning.png";
import Image4 from "../../../assets/image/gallerr-easy-marketing.png";
import LazyImage from "../../lazyImage/LazyImage";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

const FeatureGallery = ({ ref }) => {
  const intl = useIntl();
  const locale = intl.locale;
  const title = locale.includes("zh") ? "title-zh" : "title-en";
  const subtitle = locale.includes("zh") ? "subtitle-zh" : "subtitle-en";
  const description = locale.includes("zh")
    ? "description-zh"
    : "description-en";

  return (
    <div className="featureGallery">
      <Row>
        {[
          {
            image: Image1,
            title: (
              <FormattedMessage
                id="home-feature-gallery-title-1"
                defaultMessage="檢測到營運"
              />
            ),
            subTitle: (
              <FormattedMessage
                id="home-feature-gallery-subtitle-1"
                defaultMessage="全能夥伴"
              />
            ),
            otehrTitle: (
              <FormattedMessage
                id="home-feature-gallery-other-title-1"
                defaultMessage=" "
              />
            ),
            description: (
              <FormattedMessage
                id="home-feature-gallery-description-1"
                defaultMessage="除了檢測儀器，我們還幫您整合會員、營運管理與線上商城。"
              />
            ),
          },
          {
            image: Image2,
            title: (
              <FormattedMessage
                id="home-feature-gallery-title-2"
                defaultMessage="從檢測到分析"
              />
            ),
            subTitle: (
              <FormattedMessage
                id="home-feature-gallery-subtitle-2"
                defaultMessage="只要1分鐘"
              />
            ),
            description: (
              <FormattedMessage
                id="home-feature-gallery-description-2"
                defaultMessage="取代傳統人力辨識，專業儀器辨識快速準確，有效提升每日客單數。"
              />
            ),
          },
          {
            image: Image3,
            title: (
              <FormattedMessage
                id="home-feature-gallery-title-3"
                defaultMessage="輕鬆上手"
              />
            ),
            subTitle: (
              <FormattedMessage
                id="home-feature-gallery-subtitle-3"
                defaultMessage="學習零成本"
              />
            ),
            description: (
              <FormattedMessage
                id="home-feature-gallery-description-3"
                defaultMessage="檢測分析一機搞定，只要有手就能輕鬆使用！"
              />
            ),
          },
          {
            image: Image4,
            title: (
              <FormattedMessage
                id="home-feature-gallery-title-4"
                defaultMessage="AI智能分析幫您輕鬆"
              />
            ),
            subTitle: (
              <FormattedMessage
                id="home-feature-gallery-subtitle-4"
                defaultMessage="精準行銷"
              />
            ),
            description: (
              <FormattedMessage
                id="home-feature-gallery-description-4"
                defaultMessage="專業儀器檢測結合大數據分析，輕鬆推薦適合顧客的商品。"
              />
            ),
          },
        ].map((item, index) => (
          <Col lg={6} md={12} sm={12}>
            <div key={index} className="container" ref={ref}>
              <LazyImage id={index} key={index} src={item.image} />
              {/* <img src={item.image} alt={item.description} /> */}
              <div data-aos="fade-up" className="titleContainer">
                <h4 className={title}>{item.title}</h4>
                <span>{item.otehrTitle}</span>
                <h2 className={subtitle}>{item.subTitle}</h2>
              </div>
              <p className={description}>{item.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FeatureGallery;
