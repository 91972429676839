import axios from "axios";

// 取得所有news－繁體中文
export const getAllnews = async () => {
  try {
    const res = await axios.get(
      "/api/website/News/GetNews?start=0&length=50&lang=zh_TW"
    );

    if (!res.data.news) {
      throw new Error("[Get All news Failed]: No response data");
    }

    return res.data.news;
  } catch (err) {
    console.error("[Get All news Failed]:", err);
    throw err;
  }
};

// 取得所有news－英文
export const getAllnewsEN = async () => {
  try {
    const res = await axios.get(
      "/api/website/News/GetNews?start=0&length=50&lang=en_US"
    );

    if (!res.data.news) {
      throw new Error("[Get All news Failed]: No response data");
    }

    return res.data.news;
  } catch (err) {
    console.error("[Get All news Failed]:", err);
    throw err;
  }
};

// 取得所有news－th_TH
export const getAllnewsTH = async () => {
  try {
    const res = await axios.get(
      "/api/website/News/GetNews?start=0&length=50&lang=th_TH"
    );

    if (!res.data.news) {
      throw new Error("[Get All news Failed]: No response data");
    }

    return res.data.news;
  } catch (err) {
    console.error("[Get All news Failed]:", err);
    throw err;
  }
};

// 取得所有news－zh_CN
export const getAllnewsCN = async () => {
  try {
    const res = await axios.get(
      "/api/website/News/GetNews?start=0&length=50&lang=zh_CN"
    );

    if (!res.data.news) {
      throw new Error("[Get All news Failed]: No response data");
    }

    return res.data.news;
  } catch (err) {
    console.error("[Get All news Failed]:", err);
    throw err;
  }
};

// 取得單篇news
export const getPost = async (postId) => {
  try {
    const res = await axios.get(
      `/api/website/News/GetDetail?news_uid=${postId}`
    );

    if (!res.data.result) {
      return null;
      // throw new Error("[Get news detail Failed]: No response data");
    }

    return res.data.news;
  } catch (err) {
    console.error("[Get news detail Failed]:", err);
    throw err;
  }
};
