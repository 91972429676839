import React from "react";
import "./homeCarousel.scss";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import Banner1 from "../../../assets/image/home-banner-01.png";
import Banner2 from "../../../assets/image/home-banner-02.png";
import Banner3 from "../../../assets/image/home-banner-03.png";
import LazyImage from "../../lazyImage/LazyImage";
import { FormattedMessage } from "react-intl";
import ReactGA from "react-ga4";
import { useIntl } from "react-intl";

const handleGAClick = (platform) => {
  ReactGA.event({
    category: "pageview",
    action: "Click",
    label: platform,
  });
};

const HomeCarousel = () => {
  const intl = useIntl();
  const locale = intl.locale;
  const title = locale.includes("zh") ? "title-zh" : "title-en";
  const subtitle = locale.includes("zh") ? "subtitle-zh" : "subtitle-en";
  const button = locale.includes("zh") ? "button-zh" : "button-en";

  return (
    <div className="homeCarousel">
      <Carousel fade prevIcon={null} nextIcon={null} indicators={false}>
        {[
          {
            id: 1,
            image: Banner1,
            title: (
              <FormattedMessage
                id="home-banner-text1"
                defaultMessage="翻轉美容/美髮界的全新革命"
              />
            ),
          },
          {
            id: 2,
            image: Banner2,
            title: (
              <FormattedMessage
                id="home-banner-text2"
                defaultMessage="全方面打造健康生活"
              />
            ),
          },
          {
            id: 3,
            image: Banner3,
            title: (
              <FormattedMessage
                id="home-banner-text3"
                defaultMessage="輕鬆多元串接"
              />
            ),
          },
        ].map((slide, index) => (
          <Carousel.Item key={index}>
            {/* <img src={slide.image} alt={slide.title} /> */}
            <LazyImage id={slide.id} key={slide.id} src={slide.image} />
            <div className="overlay"></div>
            <Carousel.Caption className="textContainer">
              <h2 className={subtitle}>
                <FormattedMessage
                  id="home-banner-first-title"
                  defaultMessage="全球滿意客戶超過3000家"
                />
              </h2>
              <h1 className={title}>{slide.title}</h1>
              <Link to={"/contact"}>
                <button
                  onClick={() => handleGAClick("home-carousel-contact")}
                  className={button}
                >
                  <FormattedMessage
                    id="button-contact"
                    defaultMessage="立即諮詢"
                  />
                </button>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeCarousel;
