import React, { useState, useEffect } from "react";
import "./homePage.scss";
import { FormattedMessage } from "react-intl";
import Header from "../../compoments/header/Header";
import Footer from "../../compoments/footer/Footer";
import HomeCarousel from "../../compoments/home/homeCarousel/HomeCarousel";
import FeatureGallery from "../../compoments/home/featureGallery/FeatureGallery";
import GlobalMap from "../../compoments/home/globalMap/GlobalMap";
import MapNumber from "../../compoments/home/mapNumber/MapNumber";
import PartnerBrand from "../../compoments/home/partnerBrand/PartnerBrand";
import Map from "../../compoments/home/map/Map";
import RecommendImg from "../../assets/image/salon-recommend.png";
import Join from "../../compoments/join/Join";
import JoinBackground from "../../assets/image/home-join-bg.png";
import { Link } from "react-router-dom";
import FloatAd from "../../compoments/floatAd/FloatAd";
import {
  getAllnews,
  getAllnewsEN,
  getAllnewsTH,
  getAllnewsCN,
} from "../../api/news";
import ReactGA from "react-ga4";
import { useIntl } from "react-intl";
import LazyImage from "../../compoments/lazyImage/LazyImage";
import useWebMetaData from "../../hook/useWebMetaData";

const HomePage = () => {
  const [post, setPost] = useState([]);
  const intl = useIntl();
  const title = intl.formatMessage({ id: "home-title" });
  const description = intl.formatMessage({ id: "home-description" });

  // 設定網站標題與描述
  useWebMetaData(title, description);

  useEffect(() => {
    const newsFunctions = {
      en: getAllnewsEN,
      "zh-TW": getAllnews,
      // "zh-CN": getAllnewsCN,
      th: getAllnewsTH,
    };

    async function getNewsBasedOnLocale(locale) {
      const getNewsFunc = newsFunctions[locale] || getAllnewsEN;
      return await getNewsFunc();
    }
    const handleGetAllNews = async () => {
      const news = await getNewsBasedOnLocale(intl.locale);
      const latestPost = news.reduce((a, b) =>
        new Date(b.post_ts) > new Date(a.post_ts) ? a : b
      );
      setPost(latestPost);
    };
    handleGetAllNews();
  }, [intl]);

  const handleGAClick = (platform) => {
    ReactGA.event({
      category: "pageview",
      action: "Click",
      label: platform,
    });
  };

  return (
    <div className="homePage">
      <Header />
      <FloatAd post={post} />
      <HomeCarousel />
      <div className="homeContainer">
        <section className="gallery">
          <FeatureGallery />
        </section>
        <section className="global">
          <div className="linearGradient"></div>
          <div className="textContainer">
            <h1>
              <FormattedMessage
                id="home-global-title"
                defaultMessage="多位顧客肯定，致力拓展全球"
              />
            </h1>
            <h3>
              <FormattedMessage
                id="home-global-description"
                defaultMessage="從台灣出發，專注於亞太地區的美容和髮廊行業，不斷拓展全球市場。目前，我們的銷售版圖已涵蓋東北亞及東南亞，並正積極進軍歐美市場，開創更廣闊的國際市場。使用我們產品的顧客一致給予高度肯定，不僅滿意度卓越，更成功提升業績，達到業務成長。"
              />
            </h3>
          </div>
          <GlobalMap />
          <MapNumber />
          <h1 className="recommendTitle">
            <FormattedMessage
              id="home-recommend-title"
              defaultMessage="顧客好評推薦"
            />
          </h1>
        </section>
        <section className="recommend">
          <LazyImage src={RecommendImg} />
          {/* <img src={RecommendImg} alt="Salon-Recommend" /> */}
          <div className="textContainer">
            <h1>
              <FormattedMessage
                id="home-recommend-salon-recommend"
                defaultMessage="知名髮廊 ＆ 醫美診所推薦"
              />
            </h1>
            <Link to={"/recommend"}>
              <button onClick={() => handleGAClick("home-recommend-view")}>
                <FormattedMessage
                  id="home-recomend-button-text"
                  defaultMessage="查看更多"
                />
              </button>
            </Link>
          </div>
        </section>
        <section className="partner">
          <div className="textContainer">
            <h1>
              <FormattedMessage
                id="home-partner-title"
                defaultMessage="正在使用Alluring的合作夥伴"
              />
            </h1>
            <h3>
              <FormattedMessage
                id="home-partner-description"
                defaultMessage="我們已與超過50家頭皮/護膚和化妝品製造商建立了合作關係。
                附屬店鋪的數量已超過1,500家，並且仍在不斷增長。"
              />
            </h3>
          </div>
          <PartnerBrand />
        </section>
        <section className="joinSection">
          <Join
            buttonColor="var(--brand)"
            textColor="#3e3c3c"
            background={JoinBackground}
          />
        </section>
        <section className="mapSection">
          <Map />
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
